import axios from "axios";
import Cookies from "js-cookie";
import { GetVideoPlayInfo } from "@/services/config";
export default {
	data: () => {
		return {
			isShowList: true,
			videoInfo: [],
			tokenVal: "",
			ele: "",
			video: "",
			videoTime: "",
			videoId: ""
		};
	},
	created() {
		this.videoId = this.$route.params.id;
		this.videoTime = Cookies.get("time" + this.$route.params.id);
		this.tokenVal = Cookies.get("token");
		this.axios
			.post(
				GetVideoPlayInfo,
				{
					Definition: "LD",
					VideoId: this.$route.params.id
				},
				{
					headers: { token: this.tokenVal }
				}
			)
			.then(res => {
				if (res.data.code == 200) {
					let videoInfoArr = JSON.parse(res.data.data.videoInfo);
					this.videoInfo = videoInfoArr.PlayInfoList.PlayInfo[1];
				}
			});
	},
	mounted: function () {
		this.$nextTick(function () {
			this.$refs.video.currentTime = this.videoTime;
		});
	},
	beforeDestroy: function () {
		Cookies.set("time" + this.videoId, this.$refs.video.currentTime);
	},
	methods: {},
	filters: {
		// 视频标题
		setVideoTitle: function (value) {
			if (!value) return "";
			value = value.split(" ");
			return value[0];
		},
		// 视频作者
		setVideoAuthor: function (value) {
			if (!value) return "";
			value = value.split(" ");
			return value[1];
		},
		// 视频时间
		setVideoTime: function (value) {
			if (!value) return "";
			value = new Date() - value;
		}
	},
	components: {
		axios
	}
};
